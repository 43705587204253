<template>
    <div class="screen-height d-flex justify-center">
        <div class="all-width">
            <v-card class="shadow-off " loader-height="2" :loading="loading" :disabled="loading">
                <v-card-text class="pb-0">
                    <div class="d-flex align-center justify-space-between" v-if="!loading || metadata._id">

                        <div class="d-flex flex-column align-start justify-start mb-2">
                            <div class="d-flex mb-2">
                                <p class="ma-0">Home</p>
                                <p class="ma-0 px-2">/</p>
                                <p class="ma-0">{{ metadata.description }}</p>
                            </div>
                            <div class="d-flex ">
                                <h6 class="text-h5 font-weight-bold black--text">Trial balance</h6>
                                <v-tooltip bottom content-class="tooltip-bottom" >
                                    <template v-slot:activator="{ on, attrs }"> 
                                        <v-btn 
                                            v-bind="attrs"
                                            v-on="on"
                                            class="shadow-off ml-1" icon @click="refreshData()"><v-icon
                                            size="24">mdi-refresh</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Refresh</span>
                                  </v-tooltip>
                            </div>
                        </div>

                        <v-btn small color="darkgray" height="4vh" class="mr-1 white--text radius-mini"
                            v-if="($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))"
                            @click="tbuploaddata = []; tbfile = null; tbdialog = true">
                            <v-icon class="mr-1">mdi-upload-multiple</v-icon>
                            <span>Bulk Upload</span>
                        </v-btn>
                    </div>

                    <div v-if="loading && !metadata._id">
                        <v-skeleton-loader
                            type="table-heading, list-item-two-line, article, article"></v-skeleton-loader>
                    </div>
                    <div class="mb-4" v-else>
                        <div class="flex-grow-1 d-block">
                            <div class="px-0 pt-0" v-if="tb.length > 0">

                                <div class="all-width d-flex align-center justify-end">
                                    <div class="d-flex align-center black--text mt-2 mb-3"
                                        v-if="Math.round(closingbalancetotal) !== 0"><span class="mr-2">Closing Balance
                                            Difference :</span>
                                        <span v-if="Math.round(closingbalancetotal) !== 0"
                                            class="red--text text-decoration-underline subtitle-1">{{
                                                $nova.formatNumber(closingbalancetotal) }}</span>
                                    </div>
                                    <div class="d-flex align-center justify-center">

                                        <v-slide-x-reverse-transition>
                                            <lb-string v-if="showSearch" width="30vh" v-model="searchQuery" placeholder="Search.."
                                                hidedetails label=""  :isClearable="false"/>
                                          </v-slide-x-reverse-transition>
                                          <v-tooltip  bottom content-class="tooltip-bottom">
                                            <template v-slot:activator="{ on, attrs }">  
                                                <v-btn v-bind="attrs"
                                                v-on="on" icon @click="toggleSearch">
                                                    <v-icon>mdi-magnify</v-icon>
                                                </v-btn>
                                                </template>
                                                <span>Search</span>
                                            </v-tooltip>

                                          
                                        <v-tooltip bottom content-class="tooltip-bottom">
                                            <template v-slot:activator="{ on, attrs }">
                                              <span v-bind="attrs" v-on="on">
                                                <lb-activitylogwithicon :config="activitylogconfig" :url="`/v2/financialclose/analysis/gettrialbalanceuplodelogs/${metadata._id}`" />
                                              </span>
                                            </template>
                                            <span>Activity Log</span>
                                        </v-tooltip>

                                        <v-menu  :close-on-content-click="false" offset-y
                                        transition="slide-y-transition">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-tooltip left content-class="tooltip-left">
                                              <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                                              <v-btn icon v-bind="{ ...attrs, ...tooltipAttrs }" v-on="{ ...on, ...tooltipOn }"  class="ml-2 border-on"><v-icon color="caccounting">mdi-table-large-plus</v-icon></v-btn>
                                              </template>
                                              <span>Customize column</span>
                                            </v-tooltip>
                                          </template>
                        
                                        <v-list style="max-height:50vh ; overflow:hidden; z-index:13 !important"
                                            class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width">
                                            <v-list-title class="ma-0  all-width">
                                                <div
                                                    class="d-flex grayBackground align-center pa-2 pb-3 pt-3 pb-1 justify-space-between">
                                                    <p class="ma-0 font-weight-bold">Customize Column</p>
                                                </div>
                                                <v-divider></v-divider>
                                                <lb-string label="" placeholder="Search" class="px-2 mt-3 mb-2"
                                                    v-model="searchcolumn" width="30vh" :isClearable="false"
                                                    appendiconinner="mdi-magnify" hidedetails radius="8px" />
                                            </v-list-title>
                                            <v-list-item style="max-height:25vh ; overflow:scroll"
                                                class="d-flex all-width align-start pa-0 ma-0 flex-column">
                                                <template v-for="(item, index) in headers">
                                                    <v-list-item class="d-flex pa-0 px-2 ma-0 " :key="index" v-if="item.text !== 'Action'">
                                                    <v-checkbox color="caccounting" 
                                                        :ripple="false" class="pa-0 ma-0" @change="addTableHeader(item)"
                                                        hide-details :input-value="item.visible"
                                                        :label="item.text"></v-checkbox>
                                                </v-list-item>
                                                </template>
                        
                                            </v-list-item>
                        
                                        </v-list>
                                    </v-menu>
                                    
                                    </div>
                                </div>

                                <lb-datatableaction v-if="paginatedFilteredItems.length > 0" :headers="headers"
                                    :tabledata="paginatedFilteredItems" :itemsperpage="itemsPerPage"
                                    :searchterm="search" :bulkActions="false">

                                    <template v-slot:tbody>
                                        <tr v-for="item in paginatedFilteredItems" :key="item._id" class=" "
                                            :class="{ 'low-opacity': item.status === false }">
                                            <template v-for="header in headers">
                                                <td v-if="header.visible && header.value !== 'action'"
                                                    :key="header.value" class="px-2 single-line"
                                                    :class="{ 'description-header': header.value === 'description' }">
                                                    <div v-if="header.value === 'location'">
                                                        <span v-if="item.location.length > 0"
                                                            class="font-small caccounting--text caccounting-background pa-1 mr-2 radius-mini">{{
                                                                item.location[0] }}</span>
                                                        <v-tooltip top content-class="tooltip-top">
                                                            <template v-slot:activator="{ on }">
                                                                <span v-if="item.location.length > 1" v-on="on"
                                                                    class="border-on radius-mini text-caption purple--text pa-1 ">+{{
                                                                        item.location.length
                                                                        - 1 }}</span>
                                                            </template>
                                                            <div v-for="(item, index) in item.location " :key="index">
                                                                <span>{{ item }}</span>
                                                            </div>
                                                        </v-tooltip>
                                                    </div>
                                                    <div v-else-if="header.value === 'reference_detail'">
                                                        <span v-for="(x, index) in item[header.value].slice(0, 2)"
                                                            :key="index"
                                                            class="font-small cursor-pointer caccounting--text caccounting-background pa-1 mr-2 radius-mini"
                                                            @click="openTaskDetail(item[header.value])">
                                                            {{ x.ref }}
                                                        </span>

                                                        <v-tooltip top content-class="tooltip-top">
                                                            <template v-slot:activator="{ on }">
                                                                <span v-if="item.reference_detail.length > 2" v-on="on"
                                                                    class="border-on radius-mini text-caption purple--text pa-1 cursor-pointer">+{{ item.reference_detail.length - 2 }}</span>
                                                            </template>
                                                            <div v-for="(item, index) in item.reference_detail.slice(2) "
                                                                :key="index">
                                                                <span>{{ item.ref }}</span>
                                                            </div>
                                                        </v-tooltip>
                                                    </div>
                                                    <div class="" v-else-if="header.value === 'created_at'">
                                                        {{ $nova.formatDate(item.created_at) }}
                                                    </div>
                                                    <div class="" v-else-if="header.value === 'updated_at'">
                                                        {{ $nova.formatDate(item.updated_at) }}
                                                    </div>
                                                    <span v-else> {{ item[header.value] }}</span>
                                                </td>
                                            </template>
                                            <td class=" px-3 single-line border-right border-left action-data">
                                                <v-tooltip bottom content-class="tooltip-bottom">
                                                    <template v-slot:activator="{ on, attrs }" > 
                                                        <v-btn icon small v-bind="attrs"  v-on="on" @click="addEditForm(item)">
                                                            <v-icon>mdi-pencil-outline</v-icon></v-btn>
                                                    </template>
                                                    <span>Edit</span>
                                                  </v-tooltip>
                                                    <v-tooltip bottom content-class="tooltip-bottom">
                                                        <template v-slot:activator="{ on, attrs }">
                                                          <span v-bind="attrs" v-on="on">
                                                            <lb-activitysidemenuNew :config="activitylogconfig" :url="`/v2/financialclose/analysis/gettrialbalancelogs/${item._id}`" />
                                                          </span>
                                                        </template>
                                                        <span>Activity Log</span>
                                                        </v-tooltip>
                                            </td>
                                        </tr>
                                    </template>
                                </lb-datatableaction>

                                <div class="d-flex align-center justify-center" v-else>
                                    NO DATA
                                </div>

                                <Pagination :list="filtereddata" :itemsperpage="itemsPerPage"
                                    @paginated-data="setPaginatedData" />



                            </div>
                            <div v-else class="pa-0">
                                <div class="d-flex align-center pa-2">
                                    <!-- <span class="mr-4" v-if="metadata.managerlist.indexOf(userid) > -1">Trial
                                        Balance</span>
                                    <v-btn small color="primary" class="mr-1"
                                        @click="tbuploaddata = []; tbfile = null; tbdialog = true"
                                        v-if="metadata.managerlist.indexOf(userid) > -1">
                                        <v-icon class="mr-1">mdi-upload-multiple</v-icon>
                                        <span>Bulk Upload</span>
                                    </v-btn> -->
                                    <!-- <lb-drawer v-model="tbdialog" :width="(tbuploaddata.length > 0) ? '' : '400'"
                                        heading="Bulk TrialBalance Upload" :loading="loading">
                                        <template v-slot:body>
                                            <div>
                                                <div class="text-center mb-1">Upload the bulk upload trialbalance
                                                    template here.</div>
                                                <div class="d-flex align-center justify-center">
                                                    <lb-file v-model="tbfile" label="" class="py-0 my-0"
                                                        :loading="fileprocessing['__tb'] === 1" :drag="false"
                                                        hidedetails @change="readTBFile('__tb')" :displayname="false"
                                                        accept=".xlsx,.xls,.xlsm,.xlsb"
                                                        v-if="metadata.managerlist.indexOf(userid) > -1" />
                                                    <v-btn v-if="metadata.managerlist.indexOf(userid) > -1"
                                                        color="primary" small class="ml-2"
                                                        @click="$nova.downloadFile('', {}, '/v2/financialclose/analysis/getuploadtbtemplate')">Download
                                                        Template</v-btn>
                                                </div>
                                            </div>
                                            <div v-if="tbuploaddata.length > 0" class="mt-3">
                                                <v-simple-table dense class="">
                                                    <template v-slot:default>
                                                        <thead>
                                                            <th class="text-left">GL Code</th>
                                                            <th class="text-right">Opening Amount</th>
                                                            <th class="text-right">Transaction</th>
                                                            <th class="text-right">Audit Entries</th>
                                                            <th class="text-right">Closing Amount</th>
                                                            <th class="text-right">Location</th>
                                                        </thead>
                                                        <v-slide-y-transition class="" group tag="tbody"
                                                            style="overflow: hidden !important">
                                                            <tr v-for="(v, k) in tbuploaddata" :key="k" class="">
                                                                <td v-if="((tbdataerror[k] || [])[0] || [])[1] !== ''"
                                                                    class="error--text font-weight-bold">
                                                                    <v-tooltip bottom content-class="tooltip-bottom">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <div v-bind="attrs" v-on="on">
                                                                                {{ (v.data[0] || {}).glcode || "Error"
                                                                                }}
                                                                            </div>
                                                                        </template>
                                                                        <span>{{ ((tbdataerror[k] || [])[0] || [])[1]
                                                                            }}</span>
                                                                    </v-tooltip>
                                                                </td>
                                                                <td class="" v-else>{{ (v.data[0] || {}).glcode }}</td>
                                                                <td class="text-right">
                                                                    {{ $nova.formatNumber((v.data[0] ||
                                                                        {}).opening_amount) }}
                                                                </td>
                                                                <td class="text-right">
                                                                    {{ $nova.formatNumber((v.data[0] || {}).transaction)
                                                                    }}
                                                                </td>
                                                                <td class="text-right">
                                                                    {{ $nova.formatNumber((v.data[0] ||
                                                                        {}).audit_entries) }}
                                                                </td>
                                                                <td class="text-right">
                                                                    {{ $nova.formatNumber((v.data[0] ||
                                                                        {}).closing_amount) }}
                                                                </td>
                                                                <td class="text-right">
                                                                    {{ ((v.data[0] || {}).location) }}
                                                                </td>
                                                            </tr>
                                                        </v-slide-y-transition>
                                                    </template>
                                                </v-simple-table>
                                            </div>
                                        </template>
                                        <template v-slot:actions>
                                            <v-spacer></v-spacer>
                                            <div class="ml-2 error--text font-weight-bold" v-if="tberrorflag">
                                                We found few errors. Rectify the same to proceed to upload
                                            </div>
                                            <v-btn small class="ml-2" color="primary"
                                                v-else-if="tbuploaddata.length > 0" @click="uploadTBData()">Verify &
                                                Upload</v-btn>
                                        </template>
                                    </lb-drawer> -->

                                    <v-spacer></v-spacer>
                                    <v-chip v-if="tb.length === 0 " color="info" label small>Pending</v-chip>
                                    <v-chip v-else color="success" label small>Uploaded</v-chip>
                                </div>
                                <v-divider></v-divider>
                            </div>
                        </div>
                    </div>

                </v-card-text>
            </v-card>

            <lb-drawer v-model="tasklistdilog" heading="Edit Account Details" :loading="tasklistdilogloading"
                width="600">
                <template v-slot:body>
                    <v-row>
                        <v-col cols="3">
                            <span class="grey--text">Account No.</span>
                        </v-col>
                        <v-col cols="9">
                            <span class="caccounting--text caccounting-background pa-1 radius-mini">{{
                                tasklistitem.glcode }}</span>
                        </v-col>
                        <v-col cols="3">
                            <span class="grey--text">Account Name</span>
                        </v-col>
                        <v-col cols="9">
                            <span>{{ tasklistitem.name }}</span>
                        </v-col>
                        <v-col cols="3">
                            <span class="grey--text">Type</span>
                        </v-col>
                        <v-col cols="9">
                            <span>{{ tasklistitem.type }}</span>
                        </v-col>
                        <v-col cols="3">
                            <span class="grey--text">{{$store.state.LocationHeader || 'Location'}}</span>
                        </v-col>
                        <v-col cols="9">
                            <span class="caccounting--text caccounting-background pa-1 radius-mini">{{
                                tasklistitem.location[0] }}</span>
                        </v-col>

                        <v-col cols="3">
                            <span class="grey--text">Category</span>
                        </v-col>
                        <v-col cols="9">
                            <span></span>
                        </v-col>
                        <v-col cols="3">
                            <span class="grey--text">Sub Category</span>
                        </v-col>
                        <v-col cols="9">
                            <span></span>
                        </v-col>
                        <v-col cols="3">
                            <span class="grey--text">Opening Amount</span>
                        </v-col>
                        <v-col cols="9">
                            <span>({{ $nova.formatNumber(tasklistitem.opening_amount) }})</span>
                        </v-col>
                        <v-col cols="3">
                            <span class="grey--text">Transaction</span>
                        </v-col>
                        <v-col cols="9">
                            <span>({{ $nova.formatNumber(tasklistitem.transaction) }})</span>
                        </v-col>
                        <v-col cols="3">
                            <span class="grey--text">Journal Entries</span>
                        </v-col>
                        <v-col cols="9">
                            <span>({{ $nova.formatNumber(tasklistitem.audit_entries) }})</span>
                        </v-col>
                        <v-col cols="3">
                            <span class="grey--text">Closing Amount</span>
                        </v-col>
                        <v-col cols="9">
                            <span>({{ $nova.formatNumber(tasklistitem.closing_amount) }})</span>
                        </v-col>



                    </v-row>
                    <div class="d-flex align-center justify-end mb-2 mt-2">
                        <v-btn class="shadow-off"
                            @click="openSelectGlDialog(tasklistitem.reference_detail, tasklistitem.location_id)" small
                            text><v-icon class="mr-1">mdi-plus</v-icon>Add Task</v-btn>
                    </div>
                    <v-simple-table dense class="simple-table border-on radius-small"
                        v-if="(tasklistitem.reference_detail || []).length > 0">
                        <thead>
                            <tr>
                                <th class="grayBackground" scope="Name">Reference</th>
                                <th class="grayBackground" scope="columns">Description</th>
                                <th class="grayBackground" scope="category">Category</th>
                                <th class="grayBackground" scope="Action">Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(v, k) in (tasklistitem.reference_detail || [])">
                                <tr :key="k">
                                    <td>{{ v.ref }}</td>
                                    <td>{{ v.description }}</td>
                                    <td>{{ v.category }}</td>
                                    <td>
                                        <v-icon class="ma-1" color="error" @click="removeRef(v)">mdi-delete</v-icon>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </v-simple-table>
                    <div v-else class="d-flex grey--text py-4 text-center body-1">
                        No task linked 
                    </div>


                </template>
                <template v-slot:actions>
                    <v-spacer></v-spacer>
                    <v-btn small class="ml-2" color="primary"
                        @click="addUpdateGls(tasklistitem.reference_detail, tasklistitem._id)">Update</v-btn>
                </template>

            </lb-drawer>

            <lb-drawer v-model="selectgldialog" :heading="'Select GL(s)'" width="600" :loading="selectgldialogloading">
                <template v-slot:body>
                    <div>
                        <v-row>
                            <v-col cols="12" class="my-0 py-1">
                                <lb-dropdown v-model="selectgls" label="Tasklist*" itemtext="displayText"
                                    itemvalue="_id" multiple :items="locationtaskList" />
                            </v-col>
                        </v-row>
                    </div>
                </template>
                <template v-slot:actions>
                    <span class="caption">*Required</span>
                    <v-spacer></v-spacer>
                    <v-btn small color="primary" @click="addSelectedGls()">
                        <span>Add</span>
                    </v-btn>
                </template>
            </lb-drawer>










            <lb-drawer v-model="tbdialog" :Maxwidth="(tbuploaddata.length > 0) ? '1665' : '700'" heading="Bulk Upload"
                :loading="loading">
                <template v-slot:body>
                    <div>
                        <div class="text-center mb-1 d-flex justify-space-between align-center">
                            <p class="ma-0 pa-0" v-if="tbuploaddata.length === 0">Upload your document</p>
                            <p class="ma-0 pa-0 font-weight-bold" v-else>Preview</p>
                            <div class="d-flex align-center justify-center">
                                <v-btn color="white" v-if="tbuploaddata.length > 0 || reUpload" small
                                    class="ml-2 d-flex align-center  justify-space-between " @click="tbuploaddata=[]">
                                    <v-icon class="pr-1">mdi-reload</v-icon>
                                    <p class="ma-0 pa-0"> Re-Upload</p>
                                </v-btn>
                                <v-btn small class="border-dark ml-2 d-flex align-center py-4  justify-space-between "
                                    @click="$nova.downloadFile('',{},'/v2/financialclose/analysis/getuploadtbtemplate')"> <v-icon class="pt-1 pr-1">mdi-tray-arrow-down</v-icon>
                                    <p class="ma-0 pa-0"> Download Template</p>
                                </v-btn>
                            </div>
                        </div>
                        <div class="" v-if="tbuploaddata.length > 0">
                            <v-checkbox @change="dataOnlyWithError = !dataOnlyWithError"
                                label="Show only items with errors" class="my-1 mb-6" color="caccounting"
                                hide-details></v-checkbox>
                        </div>
                        <div class="d-flex align-center justify-center mt-4" v-if="tbuploaddata.length === 0">
                            <DragDropfile hidedetails :files="tbfile" @update:files="updateFilesBulk" />
                        </div>
                    </div>


                    <div v-if="tbuploaddata.length > 0" class="mt-3">
                        <v-simple-table fixed-header class="simple-table border-on radius-small" :height="tableHeight">
                            <template>
                                <thead>
                                    <tr>
                                        <th class="pa-1 grayBackground text-center">S No.</th>
                                        <th class="pa-1 grayBackground text-left">Account No.</th>
                                        <th class="pa-1 grayBackground text-right">Opening Amount</th>
                                        <th class="pa-1 grayBackground text-right">Transaction</th>
                                        <th class="pa-1 grayBackground text-right">Journal Entries</th>
                                        <th class="pa-1 grayBackground text-right">Closing Amount</th>
                                        <th class="pa-1 grayBackground text-left">{{ $store.state.LocationHeader || 'Location' }}</th>
                                        </tr>
                                    </thead>
                                    <v-slide-y-transition group tag="tbody">
                                        <tr v-for="(v, k) in filteredUploadData" :key="k" >
                                        <td class="text-center">{{ k + 1 }}</td>
                                        <td v-if="errorsonly[k]?.[0]?.[1] !== ''" class="all-width all-height" style="border: 1px solid #F3B9BA">
                                            <v-tooltip bottom content-class="tooltip-bottom">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div v-bind="attrs" v-on="on">
                                                {{ (v.data[0] || {}).glcode }}
                                                </div>
                                            </template>
                                            <span>{{ errorsonly[k]?.[0]?.[1] }}</span>
                                            </v-tooltip>
                                        </td>                                  
                                        <td v-else>{{ (v.data[0] || {}).glcode }}</td>
                                        <td class="text-right">{{ $nova.formatNumber((v.data[0] || {}).opening_amount) }}</td>
                                        <td class="text-right">{{ $nova.formatNumber((v.data[0] || {}).transaction) }}</td>
                                        <td class="text-right">{{ $nova.formatNumber((v.data[0] || {}).audit_entries) }}</td>
                                        <td class="text-right">{{ $nova.formatNumber((v.data[0] || {}).closing_amount) }}</td>
                                        <td class="text-left">
                                            <span v-if="v.data[0].location" class="caccounting-background caccounting--text pa-1 mr-1 radius-mini">
                                            {{ (v.data[0] || {}).location }}
                                            </span>
                                        </td>
                                    </tr>
                                </v-slide-y-transition>
                                </template>
                          </v-simple-table>
                        <p v-if="haserror" class="error--text ma-0 pa-0 mt-4 text-caption">*These are fields where
                            errors exist/
                            information missing. Please rectify and re-upload to continue. </p>
                    </div>
                </template>
                <template v-slot:actions>
                    <v-spacer></v-spacer>
                    <v-btn small class="ml-2" color="primary"
                        :disabled="tbuploaddata.length > 0 && !tberrorflag ? false : true" @click="uploadTBData()">
                        Upload</v-btn>
                </template>
            </lb-drawer>

            <lb-drawer v-model="selectedTaskDetails" heading="Task Details" width="600">
                <template v-slot:body>
                 <v-row>
                        <v-col cols="2" class="d-flex align-center justify-start font-weight-bold text-subtitle-1">
                            <span>Linked Task</span>
                        </v-col>
                        <v-col cols="10" class="d-flex">
                            <div class="border-on py-1 radius-small">
                                <template v-for="(item, index) in listTask">
                                    <v-chip label :color="viewTaskDetails._id === item._id ? 'caccounting' : 'white'"
                                        :key="index"
                                        :class="`pa-1 px-2 mx-1 ${(viewTaskDetails._id === item._id) ? 'white--text' : ''}`"
                                        @click="viewTaskDetail(item)">
                                        {{ item.ref }}
                                    </v-chip>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="px-2 font-weight-bold text-subtitle-1" cols="12">{{ viewTaskDetails.description
                            }}</v-col>
                        <v-col cols="3">Role</v-col>
                        <v-col cols="9">
                            <v-chip label color="lightpurple" class="caccounting--text">
                                {{ viewTaskDetails.preparer.includes(userid) ? 'Preparer' :
                                    viewTaskDetails.approver.includes(userid)?
                                'Approver':''}}
                            </v-chip>
                        </v-col>
                        <v-col cols="3">Status</v-col>
                        <v-col cols="9">{{ viewTaskDetails.stage }}</v-col>
                        <v-col cols="3">Due</v-col>
                        <v-col cols="9">{{ $nova.formatDate(viewTaskDetails.duedate) }}</v-col>
                        <v-col cols="3">{{this.$store.state.LocationHeader || 'Location'}}</v-col>
                        <v-col cols="9">{{ viewTaskDetails.location }}</v-col>

                        <v-slide-y-transition>
                            <v-row class="px-3 py-2" v-if="showMore">
                                <v-col cols="3">Priority</v-col>
                                <v-col cols="9">{{ viewTaskDetails.priority }}</v-col>
                                <v-col cols="3">Category</v-col>
                                <v-col cols="9">{{ viewTaskDetails.category }}</v-col>
                                <v-col cols="3">Sub Category</v-col>
                                <v-col cols="9">{{ viewTaskDetails.subcategory }}</v-col>
                                <v-col cols="3">Assertions</v-col>
                                <v-col cols="9">{{ viewTaskDetails.assertions }}</v-col>
                                <v-col cols="3">Purpose</v-col>
                                <v-col cols="9">{{ viewTaskDetails.purpose }}</v-col>
                                <v-col cols="3">Instruction</v-col>
                                <v-col cols="9">{{ viewTaskDetails.instruction }}</v-col>
                                <v-col cols="3">Tags</v-col>
                                <v-col cols="9">{{ viewTaskDetails.tags }}</v-col>
                            </v-row>
                        </v-slide-y-transition>
                        <v-col cols="12">
                            <v-btn small @click="showMore = !showMore" color="white" class="px-0 ">
                                <v-icon>{{ showMore ? 'mdi-chevron-up' : 'mdi-chevron-down' }} </v-icon>
                                <p class="ma-0 pa-0 text-button px-1 black--text ">
                                    {{ showMore ? 'Hide items' : '7 more itmes' }}
                                </p>
                            </v-btn>
                        </v-col>
                        <v-col cols="3" class="pa-1 ">
                            <v-card
                                class="d-flex radius-small shadow-off total_task_tab tb_tab_border flex-column align-start justify-center pa-2">
                                <v-card-title class="text-caption pb-3 pa-0"> Trial Balance </v-card-title>
                                <!-- <span class="text-subtitle-1 black--text">
                                    {{ $nova.formatNumber(((viewTaskDetails.__tbtotals || {}).total ||
                                        0).toFixed(0)) }}

                                </span> -->
                            </v-card>
                        </v-col>
                        <v-col cols="3" class="pa-1 ">
                            <v-card
                                class="d-flex radius-small shadow-off total_task_tab tb_tab_border flex-column align-start justify-center pa-2">
                                <v-card-title
                                    class="text-caption all-width pb-3 pa-0 d-flex align-center justify-space-between">
                                    Schedule Balance
                                    <v-icon small size="6">mdi-information</v-icon>
                                </v-card-title>
                                <span class="text-subtitle-1 black--text">
                                    {{
                                        $nova.formatNumber((Number((viewTaskDetails.result ||
                                            {}).value?.total_value) || 0).toFixed(0)) }}
                                </span>
                            </v-card>
                        </v-col>
                        <v-col cols="3" class="pa-1">
                            <v-card
                                class="d-flex radius-small shadow-off flex-column error-tab align-start justify-center pa-2">
                                <v-card-title
                                    class="text-caption all-width pb-3 pa-0 d-flex align-center justify-space-between">
                                    Difference
                                    <v-icon>mdi-information</v-icon></v-card-title>
                                <span class="text-subtitle-1 red--text">
                                    {{ $nova.formatNumber((((viewTaskDetails.__tbtotals ||
                                        {}).total || 0) - (Number((viewTaskDetails.result || {}).value?.total_value) ||
                                    0)).toFixed(0)) }}
                                </span>
                            </v-card>
                        </v-col>
                        <v-col cols="3" class="pa-1">
                            <v-card
                                class="d-flex radius-small shadow-off total_task_tab tb_tab_border flex-column align-start justify-center pa-2">
                                <v-card-title class="text-caption pb-3 pa-0">Threshold</v-card-title>
                                <span class="text-subtitle-1 black--text">
                                    {{ viewTaskDetails.threshold ? viewTaskDetails.threshold  : 'NA'  }}
                                </span>
                            </v-card>
                        </v-col>
                        <v-col cols="6">
                            <p class="ma-0 mb-1">Preparer</p>
                            <div class="d-flex px-1">
                                <div v-for="(preparer, index) in viewTaskDetails?.preparer" :key="index"
                                    class="avatars-group__item d-flex">
                                    <v-tooltip top content-class="tooltip-top">
                                        <template v-slot:activator="{ on }" v-if="preparer">
                                            <v-avatar v-on="on" size="25" color="caccounting"
                                                class="white--text font-small  d-flex pa-0 avatar-white-border ma-0 v-avatar">
                                                {{ getNameById(preparer)[0] }}
                                            </v-avatar>
                                        </template>
                                        <span>{{getNameById(preparer) }}</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="6">
                            <p class="ma-0 mb-1">Approver</p>
                            <div class="d-flex px-1">
                                <div v-for="(approver, index) in viewTaskDetails?.approver" :key="index"
                                    class="avatars-group__item d-flex">
                                    <v-tooltip top content-class="tooltip-top" v-if="approver">
                                        <template v-slot:activator="{ on }">
                                            <v-avatar v-on="on" size="25" color="caccounting"
                                                class="white--text font-small  d-flex pa-0 avatar-white-border ma-0 v-avatar">
                                                {{ getNameById(approver)[0] }}
                                            </v-avatar>
                                        </template>
                                        <span>{{ getNameById(approver) }}</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </v-col>




                        <v-col cols="12">
                            <v-card class="shadow-off pa-0">
                                <v-card-text class="pt-0 pb-0 d-flex align-center justify-center">
                                    <v-tabs v-model="tabify" bg-color="primary"
                                        class="border-bottom d-flex align-center justify-center" color="caccounting"
                                        height="34">
                                        <v-tab :key="0" class="text-transform-none pa-0 px-2  justify-start">
                                            Reconciliation
                                            <v-icon class="mr-2">mdi-information-outline</v-icon>
                                        </v-tab>
                                        <v-tab :key="1" class="text-transform-none pa-0 px-2  justify-start">
                                            File Attachments
                                        </v-tab>
                                        <v-tab :key="2" class="text-transform-none pa-0 px-2  justify-start">
                                            Information Request
                                        </v-tab>
                                        <v-tab :key="3" class="text-transform-none pa-0 px-2  justify-start">
                                            Linked GLs
                                        </v-tab>
                                        <v-tab :key="4" class="text-transform-none pa-0 px-2  justify-start">
                                            Comments
                                        </v-tab>
                                    </v-tabs>
                                    <v-divider></v-divider>
                                </v-card-text>
                                <v-card-text class=" pa-1">
                                    <v-tabs-items v-model="tabify">
                                        <v-tab-item :key="0">
                                            wert
                                        </v-tab-item>
                                        <v-tab-item :key="1">
                                            qwert
                                        </v-tab-item>
                                        <v-tab-item :key="2">
                                            sdfgd
                                        </v-tab-item>
                                        <v-tab-item :key="3">
                                            sdv
                                        </v-tab-item>
                                        <v-tab-item :key="4">
                                            wert
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-card-text>
                            </v-card>
                        </v-col>





                    </v-row>
                </template>
            </lb-drawer>
        </div>
    </div>
</template>

<script>
import Pagination from '../../../../views/common/Pagination.vue';
import DragDropfile from '../../../../views/Inputs/DragDropfile.vue';
export default {
    name: "financialclose_project_trialbalance",
    // props: ['props'],
    data: function () {
        return {
            right: "financialclose_project",
            metadata: {},
            tb: [],
            checklist: [],
            reflist: [],
            summary: {},
            pbcdata: {},
            // pbc: {},
            fileprocessing: {},
            fileprocessingpercent: {},
            userid: "",
            formdata: {},
            userlist: {},
            stage: -1,
            id: "",
            loading: false,
            tbfile: null,
            tbdialog: false,
            tbloading: false,
            tberrorflag: false,
            tbuploaddata: [],
            tbdataerror: [],
            tbtableheader: [
                { text: "GL Code", value: "glcode" },
                { text: "Tasklist Reference", value: "reference" },
                { text: "Type", value: "type" },
                { text: "Name", value: "name" },
                { text: "Opening Amount", value: "opening_amount", align: "right" },
                { text: "Transaction", value: "transaction", align: "right" },
                { text: "Audit Entries", value: "audit_entries", align: "right" },
                { text: "Closing Amount", value: "closing_amount", align: "right" },
                // { text: 'Action', value: 'action', align: "center", sortable: false },
            ],
            headers: [
                {
                    value: "indexno",
                    text: "SL No",
                    sortable: false,
                    filterable: false,
                    visible: true
                },
                {
                    value: "glcode",
                    text: "Account No.",
                    sortable: true,
                    filterable: true,
                    visible: true
                },
                {
                    value: "name",
                    text: "Account Name",
                    sortable: true,
                    filterable: true,
                    visible: true
                },
                {
                    value: "reference_detail",
                    text: "Tasklist Reference",
                    visible: true,
                    sortable: true,
                    filterable: true,
                },
                {
                    value: "location",
                    text: this.$store.state.LocationHeader || 'Location',
                    filterable: true,
                    visible: true
                },
                {
                    value: "type",
                    text: "Type",
                    sortable: true,
                    filterable: true,
                    visible: true
                },
                {
                    value: "opening_amount",
                    text: "Opening Amount",
                    sortable: true,
                    filterable: true,
                    datatype: "number",
                    visible: true
                },
                {
                    value: "transaction",
                    text: "Transaction",
                    sortable: true,
                    filterable: true,
                    datatype: "number",
                    visible: true
                },
                {
                    value: "audit_entries",
                    text: "Journal Entries",
                    sortable: true,
                    filterable: true,
                    datatype: "number",
                    visible: true
                },
                {
                    value: "closing_amount",
                    text: "Closing Amount",
                    sortable: true,
                    filterable: true,
                    datatype: "number",
                    visible: true
                },
                {
                    value: "created_at",
                    text: "Created At",
                    sortable: false,
                    datatype: "date",
                    lock: false,
                    visible: true
                },
                {
                    value: "updated_at",
                    text: "Updated At",
                    sortable: false,
                    datatype: "date",
                    lock: false,
                    visible: true
                },
                {
                    value: "category",
                    text: "Category",
                    sortable: false,
                    datatype: "date",
                    lock: false,
                    visible: true
                },
                {
                    value: "subcategory",
                    text: "Sub Category",
                    sortable: false,
                    datatype: "date",
                    visible: true
                },
                {
                    value: "action",
                    text: "Action",
                    sortable: false,
                    datatype: "action",
                    visible: true
                },
            ],
            formatedData: [],
            tasklistdilog: false,
            tasklistdilogloading: false,
            tasklistitem: {},
            selectgldialog: false,
            selectgldialogloading: false,
            searchcustom: "",
            selectgls: [],
            selectRefIds: [],
            activitylogconfig: {
                trialbalanceupdated: {
                    icon: "mdi-pencil",
                    color: "primary",
                    displaytext: "Updated",
                },
                tbadd: {
                    icon: "mdi-table-plus",
                    color: "primary",
                    displaytext: "Trial Balance Added",
                },
            },
            selectchecklist_id: "",
            locationtaskList: [],
            isFirst: false,
            itemsPerPage: 14,
            search: '',
            selectItem: [],
            selectedFilterItems: [],
            reUpload: false,
            UploadWithError: false,
            tableHeight: '',
            selectedTaskDetails: false,
            viewTaskDetails: '',
            paginatedFilteredItems: [],
            listTask: '',
            showMore: false,
            tabify: '',
            userNameList:[],
            showSearch:false,
            searchQuery:"",
            searchcolumn:"",
            toggleSearch:false,
            dataOnlyWithError:false,
            errorsonly:[],
            haserror:false
        };
    },
    components: {
        DragDropfile, Pagination
    },
    created() {
        this.tableHeight = window.innerHeight - 400;
        this.refreshData();
    },
    activated() {
        this.refreshData();
    },

    methods: {
        setPaginatedData(paginatedData) {
            this.paginatedFilteredItems = paginatedData;
        },
        formateData() {
            let ObjectTemp = {};
            let ArrayTemp = [];
            this.tb.forEach((item, index) => {
                ObjectTemp = {
                    _id: item._id,
                    __key: index,
                    __formatedkey: index,
                    indexno: index + 1,
                    reference_detail: item.reference_detail || [],
                    glcode: item.glcode,
                    type: item.type,
                    name: item.name,
                    location: this.locationfilter(item.location_detail),
                    location_id: item.location,
                    category: item.category,
                    subcategory: item.subcategory,
                    opening_amount: (item.opening_amount || 0).toFixed(0),
                    transaction: (item.transaction || 0).toFixed(0),
                    audit_entries: (item.audit_entries || 0).toFixed(0),
                    closing_amount: (item.closing_amount || 0).toFixed(0),
                    created_at: item.created_at,
                    status: item.status,
                    stage: item.stage,
                    updated_at: item.updated_at,
                };
                ArrayTemp.push(ObjectTemp);
            });
            this.formatedData = ArrayTemp;
        },
        refreshData() {
            this.id = this.$route.params.id || "";
            this.userid = this.$store.state.user.id;
            this.getData();
        },
        getData() {
            this.loading = true;
            return this.axios
                .post("/v2/financialclose/analysis/getdetails/" + this.id)
                .then((dt) => {
                    if (dt.data.status === "success") {
                        let data = dt.data.data[0] || {};
                        this.metadata = data.metadata || {};
                        this.stage = this.metadata.stage;
                        this.tb = data.tb || [];
                       

                            this.formateData();
                            let tbtotals = {};
                            for (const i of this.tb) {
                                for (const j of i.reference_detail || []) {
                                    tbtotals[j.ref] = tbtotals[j.ref] || { total: 0, items: [] };
                                    tbtotals[j.ref].items.push(i);
                                    tbtotals[j.ref].total =
                                        tbtotals[j.ref].total + i.closing_amount || 0;
                                }
                            }
                            this.pbcdata = {};
                            let pbccount = {};
                            for (const i of data.pbcitems || []) {
                                this.pbcdata[i._id] = i;
                                for (const j of i.reference || []) {
                                    pbccount[j] = pbccount[j] || { total: 0, completed: 0 };
                                    pbccount[j].total++;
                                    if (i.stage === 2) pbccount[j].completed++;
                                }
                            }

                            this.checklist = data.checklist || [];
                            for (const x of this.checklist) {
                                this.reflist.push(x.ref);
                                x.__pbccount = pbccount[x._id] || {};
                                if (pbccount[x._id]) console.log(pbccount[x._id]);
                                x.__tbtotals = tbtotals[x.ref] || {};
                            }
                            this.summary = data.summary || {};
                        
                    }
                })
                .catch((err) => {
                    this.$store.commit("sbError", err.message || err || "Unknown error!");
                    console.log(err);
                })
                .then(() => {
                    return this.axios.post("/v2/financialclose/analysis/getformdata");
                })
                .then((dt) => {
                    if (dt.data.status === "success") {
                        this.formdata = dt.data.data[0] || {};
                        this.userNameList=dt.data.data[0].users
                        this.userlist = {};
                        let colors = this.$nova.getColors(
                            (this.formdata.users || []).length,
                            0.2
                        );
                        // console.log(colors);
                        for (let i = 0; i < (this.formdata.users || []).length; i++) {
                            const el = (this.formdata.users || [])[i];
                            this.userlist[el._id] = el;
                            this.userlist[el._id].color = colors[i];
                        }
                    } else throw new Error(dt.data.message);
                })
                .catch((err) => {
                    this.$store.commit("sbError", err.message || err || "Unknown error!");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        readTBFile(key) {
            let file = this.tbfile[0];

            if ((file || {}).name && (this.fileprocessing[key] || 0) === 0) {
                this.fileprocessing[key] = 1;
                this.tbuploaddata = [];
                let ext = ((file || {}).name || "").split(".").pop();
                let possibleglcodes = [];
                let checklistrefs = {};
                Promise.resolve()
                    .then(() => {
                        return this.axios.post(
                            "/v2/financialclose/analysis/getpossibleglcodes"
                        );
                    })
                    .then((dt) => {
                        if (dt.data.status === "success") {
                            for (const i of dt.data.data) {
                                possibleglcodes.push(i.glcode);
                                checklistrefs[i.glcode] = i.reference || [];
                            }
                        } else throw Error("Error reading glcodes");
                        if (["xlsx", "xls", "xlsm", "xlsb"].indexOf(ext) === -1)
                            throw new "Only xlsx,xls,xlsm,xlsb file extension supported"();
                        return this.$nova.readExcelFile(file);
                    })
                    .then((dt) => {
                        if (
                            JSON.stringify(dt[0] || []) ===
                            JSON.stringify([
                                "Account No",
                                "Opening Amount",
                                "Transaction",
                                "Audit Entries",
                                "Closing Amount",
                                "Location"
                            ])
                        ) {
                            let grouplist = [];
                            for (let i = 0; i < dt.length; i++) {
                                if (i > 0) {
                                    const el = dt[i];
                                    let gname = (el[0] || "").toString();
                                    if (gname !== "" && grouplist.indexOf(gname) === -1)
                                        grouplist.push(gname);
                                }
                            }
                            let groupdata = {};
                            let grouporder = [];
                            let errordata = {};
                            this.tberrorflag = false;
                            let gstartnum = 1;
                            for (let i = 0; i < dt.length; i++) {
                                if (i > 0) {
                                    const el = dt[i];
                                    let gname = "".toString();
                                    if (gname === "") {
                                        let newgname = "g" + (gstartnum || "").toString();
                                        while (grouplist.indexOf(newgname) !== -1) {
                                            newgname = "g" + (gstartnum || "").toString();
                                            gstartnum = gstartnum + 1;
                                        }
                                        gname = newgname;
                                        if (grouplist.indexOf(gname) === -1) grouplist.push(gname);
                                    }
                                    groupdata[gname] = groupdata[gname] || [];
                                    groupdata[gname].push({
                                        group: gname,
                                        // reference: checklistrefs[el[0]] || [],
                                        glcode: el[0],
                                        opening_amount: el[1],
                                        transaction: el[2],
                                        audit_entries: el[3],
                                        closing_amount: el[4],
                                        location: el[5]
                                    });
                                    if (gname !== "" && grouplist.indexOf(gname) === -1)
                                        grouplist.push(gname);
                                    if (grouporder.indexOf(gname) === -1) grouporder.push(gname);
                                    errordata[gname] = errordata[gname] || [];
                                    let errors = ["", "", ""];
                                    let glcode = (el[0] || "").toString();
                                    if (glcode === "") errors[1] = "GL Code is required";
                                    else if (possibleglcodes.indexOf(glcode) === -1)
                                        errors[1] = "GL Code not found in Chart of Accounts";
                                    if (errors.join("") !== "") this.tberrorflag = true;
                                    errordata[gname].push(errors);
                                }
                            }
                            this.tbuploaddata = [];
                            this.tbdataerror = [];

                            for (const i of grouporder) {
                                this.tbuploaddata.push({ group: i, data: groupdata[i] });
                                this.tbdataerror.push(errordata[i]);
                            }
                            this.tbdialog = true;
                            this.errorsonly= this.tbdataerror
                        } else
                            throw Error(
                                "Columns 'Account No','Opening Amount','Transaction','Audit Entries','Closing Amount','Location' are required in the same order"
                            );
                    })
                    .catch((err) => {
                        this.$store.commit(
                            "sbError",
                            err.message || err || "Unknown error!"
                        );
                        console.log(err);
                    })
                    .finally(() => {
                        this.tbfile = null;
                        this.fileprocessing[key] = 0;
                    });
            }
        },
        uploadTBData() {
            this.tbloading = true;
            let ndt = [];
            for (const i of this.tbuploaddata) {
                ndt.push(i.data[0]);
            }

            this.axios
                .post("/v2/financialclose/analysis/bulkaddtb/" + this.id, { data: ndt })
                .then((dt) => {
                    if (dt.data.status === "success") {
                        this.tbdialog = false;
                        this.refreshData();
                        this.$store.commit("sbSuccess", "TB uploaded successfully");
                        this.tbdialog=false
                    } else throw dt.data.message || "Error uploading Trial Balance";
                })
                .catch((err) => {
                    this.$store.commit("sbError", err.message || err || "Unknown error!");
                    console.log(err);
                })
                .finally(() => {
                    this.tbloading = false;
                });
        },
        addEditForm(item) {
            this.tasklistitem = { ...item };
            this.tasklistdilog = true;
        },
        openSelectGlDialog(selectitem, location) {
            this.selectgldialog = true;
            this.selectgldialogloading = true;
            const locationid = location && location.length > 0 ? location[0] : "axyz";
            console.log(locationid, "locationidlocationidlocationidlocationidlocationid");
            this.axios
                .post("v2/financialclose/analysis/getlocationbasedtasklist/" + this.id + "/c/" + locationid)
                .then((dt) => {
                    if (dt.data.status === "success") {
                        this.selectgldialogloading = false;
                        this.locationtaskList = dt.data.data.map((item) => {
                            item.displayText = item.ref + " - " + item.description
                            return item
                        })
                    } else throw new Error(dt.data.message);
                })
                .then(() => {
                    this.selectgls = selectitem.map(item => item._id);
                })
                .catch((err) => {
                    this.$store.commit("sbError", err.message || err || "Unknown error!");
                    console.log(err);

                })
                .finally(() => {
                    this.selectgldialog = true;
                });
        },
        addSelectedGls() {
            this.tasklistitem.reference_detail = [];
            const filteredItems = this.locationtaskList.filter(item => this.selectgls.includes(item._id));
            console.log(filteredItems, "filteredItems");
            // let selectarr = [];
            for (let item of filteredItems) {
                let selectObj = { ref: item.ref, description: item.description, _id: item._id };
                this.selectRefIds.push(item._id);
                this.tasklistitem.reference_detail.push(selectObj);
            }
            //this.tasklistitem.reference_detail = selectarr;
            this.selectgldialog = false;

        },
        addUpdateGls(gllist, trialid) {
            const gllistldetails = gllist.map(list => list._id);
            const tempObject = { reference: gllistldetails };
            this.tasklistdilogloading = true;
            this.axios
                .post(`/v2/financialclose/analysis/edittrialbalance/${this.id}/c/${trialid}`, { data: tempObject })
                .then((dt) => {
                    if (dt.data.status === "success") {
                        this.$store.commit("sbSuccess", "Update successful");
                        this.tasklistdilog = false;
                        this.refreshData();
                    } else {
                        throw new Error(dt.data.message);
                    }
                })
                .catch((err) => {
                    this.$store.commit("sbError", err.message || "Unknown error!");
                })
                .finally(() => {
                    this.tasklistdilogloading = false;
                });
        },
        removeRef(item) {
            let indexItem = this.tasklistitem.reference_detail.indexOf(item);
            this.tasklistitem.reference_detail.splice(indexItem, 1);
            for (const item of this.tasklistitem.reference_detail || []) {
                this.selectRefIds.push(item._id);
            }
        },
        locationfilter(item) {
            return item.map(header => header.name);
        },

        updateFilesBulk(newFilesBulk) {
            this.tbfile = newFilesBulk;
            this.readTBFile('file')
            this.reUpload = true
            this.UploadWithError = false;
        },
        openTaskDetail(task) {
            this.listTask = task
            this.viewTaskDetail(task[0])
            this.selectedTaskDetails = true
        },
        viewTaskDetail(task) {
            console.log("qwert", task);

            this.viewTaskDetails = this.checklist.find((item) => item._id === task._id)
            console.log("sderftgyhuiouy", this.viewTaskDetails);
            // this.populateschedulelinkdialogitems(item,false);
            // this.populatetblinkdialogitems(item, false);
            // this.viewTaskDetails["isMatched"]= Math.abs((this.tblinkdialogchecklistdata.__tbtotals?.total - Number(this.schedulelinkdialogchecklistdata.result.value?.total_value) || this.tblinkdialogchecklistdata.__tbtotals?.total || Number(this.schedulelinkdialogchecklistdata.result.value?.total_value) || 0))< item.threshold ? true:false;
            // this.infomanagerReq(item);
            // this.OpenBulkUploadBox(item);
        },
        getNameById(id) {
            
            let UserName = this.userNameList.find((name) => name._id === id)
            console.log("qwertywsertyuio",UserName);

            return UserName ? UserName.name : ""
        },

        addTableHeader(item) {
          this.headers.find((x) => x === item ? x.visible = !x.visible : "")
        },

    },
    computed: {
        closingbalancetotal() {
            return this.formatedData.reduce((total, item) => total + parseInt(item.closing_amount), 0);
        },
        groupedItems() {
            const grouped = {};
            this.checklist.forEach(item => {
                if (!grouped[item.category]) {
                    grouped[item.category] = [];
                }
                grouped[item.category].push(item);
            });
            return grouped;
        },
        filtereddata(){
            console.log("wertyui",this.searchQuery);
            
            const searchTerm = this.searchQuery.trim().toLowerCase();
            return this.formatedData.filter(item =>
                item.name.toLowerCase().includes(searchTerm)
            );
        },
        filteredUploadData() {
            console.log("Original Data:", this.tbuploaddata);
            if (this.dataOnlyWithError) {
            return this.tbuploaddata.filter((_, index) => {
                const hasError = this.tbdataerror[index]?.some(errors =>
                errors.some(msg => msg.trim() !== "")
                );
                console.log(`Row ${index + 1}: Has Error?`, hasError);
                return hasError;
            });
            }
            return this.tbuploaddata;
        }
    },
    watch: {
        tbdataerror: {
            handler() {
            this.errorsonly = this.tbdataerror.filter(item =>
                item.some(innerArray => innerArray.some(value => value.trim() !== ""))
            );
            this.haserror = this.errorsonly.flat(2).some(field => field.trim() !== "");
            },
            deep: true,
            immediate: true, // Optional: run initially
        }
    },
};
</script>